type STAGE_KIND = 'development' | 'staging' | 'stagingv3' | 'qa' | 'production' | 'test' | 'e2e'
const STAGE: STAGE_KIND = process.env.REACT_APP_E2E
  ? 'e2e'
  : process.env.REACT_APP_ENV || 'development'

export default {
  STAGE,
  development: {
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    },
    endpoint: process.env.REACT_APP_ENDPOINT,
    htmlPreviewEndpoint: process.env.REACT_APP_HTML_PREVIEW_ENDPOINT,
    editorUrl: process.env.REACT_APP_EDITOR_URL,
    translationDbUrl: process.env.REACT_APP_TRANSLATION_DB_URL,
    proxyTenant: '',
    headerColor: process.env.REACT_APP_HEADER_COLOR,
    headerTitle: process.env.REACT_APP_HEADER_TITLE
  },
  staging: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_OCZtVM8qf',
      userPoolWebClientId: '6iqljte9sng3kc4fj1bunkj71n'
    },
    endpoint: 'https://api-misumi-stg.translation-dashboard.com',
    htmlPreviewEndpoint: 'https://mtd-v3.stg.translation-proxy.com',
    editorUrl: 'https://editor-misumi-stg.translation-dashboard.com',
    translationDbUrl: '',
    proxyTenant: '',
    headerColor: '#007bff',
    headerTitle: 'MISUMI Translation Dashboard (STG)'
  },
  stagingv3: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_RuLKZuK7l',
      userPoolWebClientId: 'hf29rbelohm7fee4u20hufumu'
    },
    endpoint: 'https://mefxdoh40e.execute-api.ap-northeast-1.amazonaws.com/stg-v3',
    htmlPreviewEndpoint: 'stg.translation-proxy.com',
    editorUrl: 'https://editor-misumi-stg-v3.translation-dashboard.com',
    translationDbUrl: 'https://mtd-v3.textdb-browser-stg.cistate.io/',
    proxyTenant: 'mtd-v3',
    headerColor: '#007bff',
    headerTitle: 'MISUMI Translation Dashboard (STG v3)'
  },
  qa: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_PKiSBnE5v',
      userPoolWebClientId: 'eql5e1h405r1pj0qqaaqg4in9'
    },
    endpoint: 'https://api-misumi-qa.translation-dashboard.com',
    htmlPreviewEndpoint: '',
    editorUrl: '',
    translationDbUrl: '',
    proxyTenant: '',
    headerColor: 'black',
    headerTitle: 'MISUMI Translation Dashboard (QA)'
  },
  production: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_vnwFTLmKg',
      userPoolWebClientId: '1gbvdbqc0h6e61peum41qu6fdh'
    },
    endpoint: 'https://api-misumi.translation-dashboard.com',
    htmlPreviewEndpoint: 'p.translation-proxy.com',
    editorUrl: 'https://editor-misumi.translation-dashboard.com',
    translationDbUrl: '',
    proxyTenant: 'mtd-v3',
    headerColor: 'black',
    headerTitle: 'MISUMI Translation Dashboard'
  },
  test: {
    Auth: {
      identityPoolId: '',
      region: '',
      userPoolId: '',
      userPoolWebClientId: ''
    },
    endpoint: '',
    htmlPreviewEndpoint: '',
    editorUrl: '',
    translationDbUrl: '',
    proxyTenant: '',
    headerColor: 'black',
    headerTitle: 'MISUMI Translation Dashboard (TEST)'
  },
  e2e: {
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID_E2E,
      region: process.env.REACT_APP_REGION_E2E,
      userPoolId: process.env.REACT_APP_USER_POOL_ID_E2E,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_E2E
    },
    endpoint: process.env.REACT_APP_ENDPOINT_E2E,
    htmlPreviewEndpoint: process.env.REACT_APP_HTML_PREVIEW_ENDPOINT_E2E,
    editorUrl: process.env.REACT_APP_EDITOR_URL_E2E,
    translationDbUrl: '',
    proxyTenant: '',
    headerColor: 'black',
    headerTitle: 'MISUMI Translation Dashboard (E2E)'
  }
}
